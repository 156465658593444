import React from 'react';

const PartyLoader = () => {
  return (
    <div className="party-loader">
      <div className="balloon"></div>
      <div className="balloon"></div>
      <div className="balloon"></div>
      <div className="confetti"></div>
      <div className="confetti"></div>
      <div className="confetti"></div>
      <p>Get ready to party!</p>
    </div>
  );
};

export default PartyLoader; 