import { Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import PartyLoader from './components/PartyLoader';

// Lazy load components
const Home = lazy(() => import('./pages/Home'));
const Layout = lazy(() => import('./components/Layout'));
const ProductDes = lazy(() => import('./pages/ProductDes'));
const RecipeDetail = lazy(() => import('./components/recipeDetailed'));
const PartySnack = lazy(() => import('./components/AboutSnackster'));
const ScrollToTop = lazy(() => import('./components/RouteScrollTop'));
const ProductPage = lazy(() => import('./components/ProductPage'));
const RecipePage = lazy(() => import('./components/recipePage'));
const ProductCollections = lazy(() => import('./components/ProductCollections'));

function App() {
  useEffect(() => {
    Aos.init({
      once: true,
    });
  }, []);

  return (
    <Suspense fallback={<PartyLoader />}>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/about/' element={<PartySnack />} />
          <Route path='/products/' element={<ProductPage />} />
          <Route path="/products/:productUrl" element={<ProductDes />} />
          <Route path="/recipes/" element={<RecipePage />} />
          <Route path="/recipes/:recipeSlug" element={<RecipeDetail />} />
          <Route path="/collections/:collectionUrl" element={<ProductCollections />} />
          <Route path="*" element={<div>404 - Page Not Found</div>} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
